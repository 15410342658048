import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button, } from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';
import { AgCharts } from 'ag-charts-react';
//import User from './user';


export default function Accidentinspector({userJwt, refreshJwt, vehicle}) {
const [is_loading, updateIsLoading] = React.useState(false);
const [has_searched, updateHasSearched] = React.useState(false);
  const roundBack = (date,hours) => {
    date.setHours(date.getHours() - hours);
    date.setMinutes(0, 0, 0);
    return date;
  }
  const [searchdate, setSearchDate] = useState(roundBack(new Date(),12));
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",driver_job_data:[],vehicle_telemetry_data:[],res_schedule_data:[],series_data_hbha:[],series_data_speed:[]})
  
  const [seriesData, updateSeriesData] = useState([{'hbha_timestamp':0,'hbha_datetime':'-','hbha_value':0}])
  const [seriesDataSpeed, updateSeriesDataSpeed] = useState([{'speed_timestamp':0,'speed_datetime':'--','speed_value':0}])
  const [seriesMinDatetime, updateSeriesMinDatetime] = useState(roundBack(new Date(),11));  // Keeping this in case we want to use it for display
  const [seriesMaxDatetime, updateSeriesMaxDatetime] = useState(roundBack(new Date(),13));  // Keeping this in case we want to use it for display
  const [seriesMinTimestamp, updateSeriesMinTimestamp] = useState(0);
  const [seriesMaxTimestamp, updateSeriesMaxTimestamp] = useState(5702755185);

  const [chartOptions, setChartOptions] = useState({
    data: seriesData,
    title: {text: 'Hard Brake and Hard Acceleration Events over Time'},
    subtitle: {text: '+/- 24 hours from selected time, miles per hour per second'},
    series: [
      { type: 'scatter', xKey: 'hbha_timestamp', yKey: 'hbha_value' }
    ],
    axes: [
        {
          type: "number",
          position: "bottom",
          min: seriesMinTimestamp,
          max: seriesMaxTimestamp,
          label: {
              formatter: (params) => {
                const dataItem = chartOptions.data.find((item) => item.hbha_timestamp === params.value);
                return dataItem ? dataItem.hbha_datetime : params.value;
              }
          }
        },
        {
            type: "number",
            position: "left",
            label: {
                format: "#{.1f} mph/s"
            }
        }
    ]
  })
  const [chartOptionsSpeed, setChartOptionsSpeed] = useState({
    data: seriesDataSpeed,
    title: {text: 'Speeding Events over Time'},
    subtitle: {text: '+/- 24 hours from selected time, miles per hour'},
    series: [
      { type: 'line', xKey: 'speed_timestamp', yKey: 'speed_value' }
    ],
    axes: [
        {
          type: "number",
          position: "bottom",
          min: seriesMinTimestamp,
          max: seriesMaxTimestamp,
          label: {
              formatter: (params) => {
                const dataItemSpeed = chartOptionsSpeed.data.find((item) => item.speed_timestamp === params.value);
                return dataItemSpeed ? dataItemSpeed.speed_datetime : params.value;
              }
          }
        },
        {
            type: "number",
            position: "left",
            label: {
                format: "#{.1f} mph"
            }
        }
    ]
  })
  const columns = [
      {
        headerName: 'Platform',
        field: 'trip_empl',
        filter: true
      },
      {
        headerName: 'Start Datetime',
        field: 'start_datetime',
        filter: true
      },
      {
        headerName: 'End Datetime',
        field: 'end_datetime',
        filter: true
      }
  ];
  const columns_schedule_data = [
      {
        headerName: 'Driver ID',
        width: 100,
        field: 'driver_id',
        filter: true,
        pinned: 'left',
        cellRenderer: params => {
            let val = params.value
            var link = '/user/' + String(val) 
            return( <NavLink to={link} index = {val}>{val}</NavLink>)
        }
      },
      {
        headerName: 'Driver Name',
        field: 'driver_name_full',
        filter: true
      },
      {
        headerName: 'Start Datetime',
        field: 'start_datetime',
        filter: true
      },
      {
        headerName: 'End Datetime',
        field: 'end_datetime',
        filter: true
      }
  ];
  const columns_vehicle_telemetry = [
      {
        headerName: 'Data Datetime',
          field: 'data_datetime',
          filter: true
      },
      {
        headerName: 'Data Timestamp',
          field: 'data_timestamp',
          filter: true
      },
      {
        headerName: 'Car OEM',
          field: 'car_oem',
          filter: true
      },
      {
        headerName: 'Odometer (miles)',
          field: 'odometer',
          filter: true
      },
      {
        headerName: 'Odometer Datetime',
          field: 'odometer_datetime',
          filter: true
      },
      {
        headerName: 'Odometer Timestamp',
          field: 'odometer_timestamp',
          filter: true
      },
      {
        headerName: 'Latitude',
          field: 'latitude',
          filter: true
      },
      {
        headerName: 'Longitude',
          field: 'longitude',
          filter: true
      },
      {
        headerName: 'GPS Datetime',
          field: 'position_datetime',
          filter: true
      },
      {
        headerName: 'GPS Timestamp',
          field: 'position_timestamp',
          filter: true
      },
      {
        headerName: 'Speed (mph)',
          field: 'speed',
          filter: true
      },
      {
        headerName: 'Speed Timestamp',
          field: 'speed_timestamp',
          filter: true
      },
      {
        headerName: 'Speed Datetime',
          field: 'speed_datetime',
          filter: true
      },
      {
        headerName: 'Accel/Brake (mph per sec)',
          field: 'hbha',
          filter: true
      },
      {
        headerName: 'Accel/Brake Datetime',
          field: 'hbha_datetime',
          filter: true
      },
      {
        headerName: 'Accel/Brake Timestamp',
          field: 'hbha_timestamp',
          filter: true
      }
  ];
  

  function formatSeriesData(thisSeriesData, seriesType)
  {
    if(!thisSeriesData){
      return;
    }
    var seriesDataFormatted = []
    switch(seriesType)
    {
      case 'hbha':
            thisSeriesData.map(function(object, index){
              var thisValue = object['hbha_value']
              var thisDatetimeStart = object['hbha_datetime']
              var thisDatetime = new Date(thisDatetimeStart)
              var thisEntry = {'hbha_datetime':thisDatetime, 'hbha_value':thisValue}
              seriesDataFormatted.push(thisEntry)
            })
        break;
      case 'speed':
        thisSeriesData.map(function(object, index){
          var thisValue = object['speed_value']
          var thisDatetimeStart = object['speed_datetime']
          var thisDatetime = new Date(thisDatetimeStart)
          var thisEntry = {'speed_datetime':thisDatetime, 'speed_value':thisValue}
          seriesDataFormatted.push(thisEntry)
        })
      break;
    }
    return seriesDataFormatted
  }


  async function fetchPageData() {
    updateIsLoading(true)
    if(vehicle)
    {
        if(vehicle['resp'])
        {
            if(vehicle['resp'][0])
            {
                if(vehicle['resp'][0]['car_id'])
                {
                    //console.log('accidentinspector vehicle '+ vehicle['resp'][0]['car_id'])
                    let session = await Auth.currentSession()      
                    let accessToken = session.getAccessToken()
                    const myInit = {queryStringParameters:{ 
                        "back_end": process.env.REACT_APP_BACKEND,
                        "jwt": userJwt,
                        "api_endpoint":"accidentinspector",
                        "vehicle_id":vehicle['resp'][0]['car_id'],
                        "datetime":searchdate
                    }};
                    try {
                        const res = await API.get('adminWebTools','/ago',myInit)
                        //console.log('accidentinspector response: '+JSON.stringify(res))
                        updateRes(res)
                        updateSeriesMinTimestamp(res.series_min_timestamp)
                        updateSeriesMaxTimestamp(res.series_max_timestamp)
                       
                        /* graph 1 hbha */
                        updateSeriesData(res.series_data_hbha)
                        var tchartOptions = chartOptions
                        tchartOptions.data = res.series_data_hbha
                        tchartOptions.axes[0].min = res.series_min_timestamp
                        tchartOptions.axes[0].max = res.series_max_timestamp
                        setChartOptions(tchartOptions)
                        /* end graph 1 hbha */
                        /* graph 2 speed */
                        updateSeriesDataSpeed(res.series_data_speed)
                        tchartOptions = chartOptionsSpeed
                        tchartOptions.data = res.series_data_speed
                        tchartOptions.axes[0].min = res.series_min_timestamp
                        tchartOptions.axes[0].max = res.series_max_timestamp
                        setChartOptionsSpeed(tchartOptions)
                        /* end graph 2 speed */
                        updateHasSearched(true)
                        if('detail' in res)
                        {
                            if(res['detail'].includes("token"))
                            {
                                refreshJwt()
                            }
                        }
                        updateIsLoading(false)
                    }
                    catch (e) {
                        console.log('accidentinspector error: '+JSON.stringify(e));
                        if(e.response.status === 401)
                        {
                            refreshJwt()
                        }
                        updateIsLoading(false)
                    }
                }
            }
        }
    }
  }




  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Accident Detective</Heading>
                    <Text style={{width:'100%'}}>Enter a date to search for vehicle and driver data around that time, including telemetry, driver jobs and more.</Text>
                    <Flex direction="column" alignItems="flex-start">
                        <Text>Date to search (vehicle local time):</Text>
                        <DatePicker
                              name="searchdate"
                              selected={searchdate}
                              onChange={(date) => setSearchDate(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              timeIntervals={60}
                            />
                    </Flex>
                    <View>
                        <Button onClick={() => fetchPageData()}>
                            SUBMIT
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: Enter a date and click submit to search, no data initially loaded.</i></Text>
                    {
                        has_searched === false ? <Text>Search a date to investigate.</Text> :
                        <Flex direction="column">
                            <View style={{width:"100%"}}>
                              <Heading level={4}>Reservation Match</Heading>
                              <Text>Reservation for this vehicle at the selected time{/*searchdate === false ? undefined : searchdate.toLocaleDateString() + " (vehicle timezone)"*/}</Text>
                              {
                                  typeof(res.res_schedule_data) === 'undefined' ?  
                                  <Text>Error locating reservation on the schedule at this time.</Text>
                                  :
                                  typeof(res.res_schedule_data[0]) === 'undefined' ?  
                                  <Text>No reservation on the schedule at this time.</Text>
                                  :
                                  <View 
                                      className="ag-theme-quartz" // applying the grid theme
                                      style={{ height: 150 }} // the grid will fill the size of the parent container
                                  >
                                          <AgGridReact
                                              rowData={res.res_schedule_data}
                                              columnDefs={columns_schedule_data}
                                              rowSelection={'multiple'}
                                              paginationPageSize={150}
                                              paginationPageSizeSelector={[10, 25, 50]}
                                              enableCellTextSelection={true}
                                          />
                                  </View>
                              }
                            </View>
                            <View style={{width:"100%"}}>
                                <Heading level={4}>Jobs for Reservation Driver</Heading>
                                <Text>Jobs for the Driver of the Reservation Match within +/- 24 hours of the selected time{/*searchdate === false ? undefined : searchdate.toLocaleDateString() + " (vehicle timezone)"*/}.</Text>
                                {
                                    typeof(res.driver_job_data) === 'undefined' ?  
                                    <Text>No jobs found within range.</Text>
                                    :
                                    typeof(res.driver_job_data[0]) === 'undefined' ?  
                                    <Text>No jobs found within range.</Text>
                                    :
                                    <View 
                                        className="ag-theme-quartz" // applying the grid theme
                                        style={{ height: 500 }} // the grid will fill the size of the parent container
                                    >
                                            <AgGridReact
                                                rowData={res.driver_job_data}
                                                columnDefs={columns}
                                                rowSelection={'multiple'}
                                                paginationPageSize={500}
                                                paginationPageSizeSelector={[10, 25, 50]}
                                                enableCellTextSelection={true}
                                            />
                                    </View>
                                }
                            </View>
                            <View style={{width:"100%"}}>
                                <AgCharts options={chartOptions} />
                            </View>
                            <View style={{width:"100%"}}>
                                <AgCharts options={chartOptionsSpeed} />
                            </View>
                            <View style={{width:"100%"}}>
                                <Heading level={4}>Vehicle Telemetry</Heading>
                                <Text>Telemetry readings for this vehicle within +/- 24 hours of the selected time{/*searchdate === false ? undefined : searchdate.toLocaleDateString() + " (vehicle timezone)"*/}</Text>
                                {
                                    typeof(res.vehicle_telemetry_data) === 'undefined' ?  
                                    <Text>No telemetry.</Text>
                                    :
                                    typeof(res.vehicle_telemetry_data[0]) === 'undefined' ?  
                                    <Text>No telemetry.</Text>
                                    :
                                    <Flex direction="column" style={{width:"80vw"}}>
                                        <View 
                                            className="ag-theme-quartz" // applying the grid theme
                                            style={{ height: 500 }} // the grid will fill the size of the parent container
                                        >
                                                <AgGridReact
                                                    rowData={res.vehicle_telemetry_data}
                                                    columnDefs={columns_vehicle_telemetry}
                                                    rowSelection={'multiple'}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    enableCellTextSelection={true}
                                                />
                                        </View>
                                    </Flex>
                                }
                            </View>
                            <View style={{width:"100%"}}>
                                <Heading level={4}>All Vehicle Reservations</Heading>
                                <Text>All reservations for this vehicle within +/- 24 hours of the selected time{/*searchdate === false ? undefined : searchdate.toLocaleDateString() + " (vehicle timezone)"*/}.</Text>
                                {
                                    typeof(res.all_res_schedule_data) === 'undefined' ?  
                                    <Text>No reservations found within range.</Text>
                                    :
                                    typeof(res.all_res_schedule_data[0]) === 'undefined' ?  
                                    <Text>No reservations found within range.</Text>
                                    :
                                    <View 
                                        className="ag-theme-quartz" // applying the grid theme
                                        style={{ height: 500 }} // the grid will fill the size of the parent container
                                    >
                                            <AgGridReact
                                                rowData={res.all_res_schedule_data}
                                                columnDefs={columns_schedule_data}
                                                rowSelection={'multiple'}
                                                paginationPageSize={500}
                                                paginationPageSizeSelector={[10, 25, 50]}
                                                enableCellTextSelection={true}
                                            />
                                    </View>
                                }
                            </View>
                        </Flex>
                    }
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
