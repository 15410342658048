import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Userreservations({userJwt, refreshJwt, userId}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const columns = [
      {
          field: 'modified',
          filter: true
      },
      {
          field: 'action',
          filter: true,
          width: 150
      },
      {
          field: 'res_id',
          filter: true,
          cellRenderer: params => {
              let val = params.value
              let link = '../reservation/' + String(val) 
              return( <NavLink to={link} index = {val}>{val}</NavLink>)
          }
      },
      {
          field: 'car_id',
          filter: true,
          cellRenderer: params => {
              let val = params.value
              let link = '../vehicle/' + String(val) 
              return( <NavLink to={link} index = {val}>{val}</NavLink>)
          }
      },
      {
          field: 'version',
          filter: true
      },
      {
          field: 'status',
          filter: true
      },
      {
          field: 'start_string',
          filter: true
      },
      {
          field: 'end_string',
          filter: true
      },
      {
          field: 'name',
          filter: true
      },
      {
          field: 'vin',
          filter: true
      },
      {
          field: 'license',
          filter: true
      }
  ];
  

  async function fetchPageData() {
    updateIsLoading(true)
    if(userId > 0)
    {
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"driverreservationhistory",
            "driver_id":userId
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updateRes(res)
            if('detail' in res)
            {
                if(res['detail'].includes("token"))
                {
                    refreshJwt()
                }
            }
            updateIsLoading(false)
        }
        catch (e) {
            console.log('userreservations driverreservationhistory error: '+toString(e));
            if(e.response.status === 401)
            {
                refreshJwt()
            }
            updateIsLoading(false)
        }
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count, userJwt])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Reservations</Heading>
                    <Text style={{width:'100%'}}>This is the history of the driver's vehicle rentals in the mobile app</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
