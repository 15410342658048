
import { useParams } from "react-router-dom";
import { NavLink} from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';
import Loadingmini from '../components/loadingmini';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import User from './user';

export default function Users() {
    let params = useParams();
    const columns = [
        {
            headerName: 'ID',
            width: 85,
            field: 'id',
            filter: true,
            pinned: 'left',
            cellRenderer: params => {
                let val = params.value
                if(displaySideDock===false)
                {
                    var link = '/user/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                } else {
                    return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
                }
            }
        },
        {
            headerName: 'First Name',
            field: 'name_first',
            filter: true
        },
        {
            headerName: 'Last Name',
            field: 'name_last',
            filter: true
        },
        {
            headerName: 'Email',
            field: 'email',
            filter: true
        },
        {
            headerName: 'Phone',
            field: 'phone',
            filter: true
        },
        {
            headerName: 'License#',
            field: 'license',
            filter: true
        },
        {
            headerName: 'License State',
            field: 'license_state',
            width: 85,
            filter: true
        },
        {
            headerName: 'Insurance $',
            field: 'total_insurance_costs',
            filter: true
        },
        {
            headerName: 'Miles Driven',
            field: 'total_miles',
            filter: true
        },
        {
            headerName: 'Hours Driven',
            field: 'total_hours',
            filter: true
        },
        {
            headerName: 'Gig Liability Uninsured Miles',
            field: 'gig_liab_uninsured_mls',
            filter: true
        },
        {
            headerName: 'Gig Physical Uninsured Miles',
            field: 'gig_phys_dam_uninsured_mls',
            filter: true
        },
        {
            headerName: 'Safety Bucket',
            field: 'safety_bucket',
            filter: true
        },
        {
            headerName: 'Safety Score',
            field: 'safety_score',
            filter: true
        },
        {
            headerName: 'Has Gig Trips?',
            field: 'driver_has_gig_trips',
            filter: true
        },
        {
            headerName: 'Had Reservation?',
            field: 'had_reservations',
            filter: true
        },
    ];
    const [users, updateUsers] = useState({status:"Loading... if this message stays - email us at info@joinago.com",resp:[]})
    const [is_loading, updateIsLoading] = React.useState(false);
    const [count, setCount] = useState(0);
    const [displaySideDock, updateDisplaySideDock] = React.useState(false);
    const [selectedObjectId, updateSelectedObjectId] = React.useState(false);

    async function selectObject(val) {
        updateDisplaySideDock(false);
        await updateSelectedObjectId(val);
        updateDisplaySideDock(true);
    }


    async function fetchPageData() {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        var jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"drivers"
        }};
        //console.log(JSON.stringify(myInit))
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('updateUsers: '+JSON.stringify(res));
            updateUsers(res)
            //console.log('success');
        }
        catch (e) {
            const res = {status:"Error retrieving Users or User not permissioned - email us at developer@agorisk.com: "+toString(e),resp:[]}
            console.log(JSON.stringify(res))
            updateUsers(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        fetchPageData()
      },[count])

    return(
        <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
            direction='column'      //direction={{ base: 'column', large: 'row' }}
            //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
            //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
            //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
            wrap="wrap" //nowrap
            gap="1rem"
        >
            <View style={{padding:"1rem"}}>
                <Flex
                    direction='column'
                >
                    <Heading level={1}>Drivers</Heading>
                    <Text>These are the drivers in your fleet, even if they haven't driven yet. Insurance costs are calculated for the last 7 days.</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    {is_loading ? <Loadingmini /> : 
                    <Flex wrap="wrap" gap="2rem" >
                        <Flex direction="column" style={displaySideDock ? {width:"25vw"} : {width:"80vw",maxWidth:"2200px"}}>
                            <Divider />
                            <View 
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: 750 }} // the grid will fill the size of the parent container
                            >
                                    <AgGridReact
                                        rowData={users.resp}
                                        columnDefs={columns}
                                        rowSelection={'multiple'}
                                        paginationPageSize={500}
                                        paginationPageSizeSelector={[10, 25, 50]}
                                        enableCellTextSelection={true}
                                    />
                            </View>
                            <Divider />
                        </Flex>
                        {
                            displaySideDock === false ? 
                            <Flex direction="column">
                                <Divider />
                                <Button onClick={() => updateDisplaySideDock(true)}>
                                    ACTIVATE SIDE DOCK
                                </Button>
                                <Divider />
                            </Flex> 
                            :
                            selectedObjectId===false || selectedObjectId < 1 ? <Text><i>Please select a user by clicking their user ID in the table...</i></Text> :
                            <Flex direction="column" style={{width:"55vw"}}>
                                <Divider />
                                <Heading level={2}>Driver Details</Heading>
                                <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplaySideDock(false)}>To open drivers in full-screen mode, click here to hide the side dock.</Button></Text>
                                <User paramUserId={selectedObjectId} />
                                <Divider />
                            </Flex>
                        }
                    </Flex>
                    }
                </Flex>
            </View>
        </Flex> 
    );
}
